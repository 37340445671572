@import "variables.less";

// Clearfix
// --------
// For clearing floats like a boss h5bp.com/q
.clearfix {
  *zoom: 1;
  &:before,
  &:after {
    display: table;
    content: "";
    // Fixes Opera/contenteditable bug:
    // http://nicolasgallagher.com/micro-clearfix-hack/#comment-36952
    line-height: 0;
  }
  &:after {
    clear: both;
  }
}

.left {
  float: left;
}

.right {
  float: right;
}

.center {
  display: block;
  margin: 0 auto;
}

//pour ie
a img {
    border: none;
}

@font-face {
    font-family: 'mulibold';
    src: url('fonts/muli-bold-webfont.woff2') format('woff2'),
         url('fonts/muli-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'muliregularitalic';
    src: url('fonts/muli-italic-webfont.woff2') format('woff2'),
         url('fonts/muli-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'muliregular';
    src: url('fonts/muli-webfont.woff2') format('woff2'),
         url('fonts/muli-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

body {
    background-color: @fond;
    margin: 0;
    padding: 5px 0 0 0;
    color: @text;
    font-family: muliregular, "Palatino Linotype",Georgia,"Times New Roman",Times,Serif;
}

a {
   outline: 0;
}
.wrapper {
    width: 866px;
    margin: 0 auto;
}

.main {
    background: url("img/background-page.png") bottom right no-repeat;
    overflow: hidden;
    position: relative;
    .bg-mask {
        background-image: url("img/background-mask.png");
        bottom: 0;
        left: 0;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;
        z-index: -2;
    }
}

header {
    width: 100%;
    height: 276px;
    background-image: url("img/Header.jpg");
    position: relative;
    nav {
        z-index: 100;
        position: absolute;
        top: 242px;
        height: 34px;
        width: 100%;
        ul {
            background-color: @navigation-fond;
            display: block;
            margin: 0;
            padding: 0 0 0 3px;
            li {
                display: inline-block;
                line-height: 34px;
                a {
                    display: block;
                    height: 28px;
                    margin: 6px 1px 0 1px;
                    background-color: @navigation-link-rest;
                    text-decoration: none;
                    color: @navigation-link-color;
                    font-size: 0.925em;
                    line-height: 28px;
                    padding: 0 10px;
                    &:hover {
                        background-color: @navigation-link-hover;
                    }
                    
                }
                &.active a {
                    color: #000;
                    background-color: @navigation-link-active !important;
                }
            }
        }
    }
}

section {
    min-height: 400px;
    aside nav {
        position: absolute;
        left: 1em;
        width: 20px;
        a{
            display: inline-block;
            height: 14px;
            width: 20px;
            .ir {
                text-indent: -9990px;
                display: none;
            }
        }
    }
    article {
        padding: 0 4em;
        line-height: 1.3;
        h1 {
            color: #000;
            font-size: 1.5em;
            margin-bottom: 1em;
        }
        ul {
            color: @navigation-link-rest;
            list-style: none;
            padding-left: 4em;
            li {
                color: @text;
                line-height: 1.5em;
                &:before {
                    content: "• ";
                    color: @navigation-link-rest; /* or whatever color you prefer */
                    font-style: normal;
                    font-weight: bold;
                }
            }
        }
        a {
            color: @text;
            text-decoration: none;
            border-bottom:1px dashed;
        }
        .img-center {
            text-align: center;
            img {
                margin: 1em;
            }
        }
        
    }
    .partenaires {
        article {
            margin-bottom: 1em;
            h3 a {
                border-bottom: none;
            }
            .no-dash {
                border-bottom: none;
            }
            p {
                color: #323938;
            }
            .grid-image img {
                margin: 0.5em;
            }
            img.partenaires {
                margin:-20px 1em 0;
            }
        }
    }
}

.image-full {
    max-width: 500px;
    max-height: 373px;
    margin: 0 auto;
    display: block;
}

.image-plan-deauville {
    width: 95%;
    display: block;
    margin: 0 auto;
}

#gallery {
    width: 500px;
    margin: 0 auto;
    .thumbs {
        margin-top: 1em;
        img {
            margin: 0 10px 10px;
            height: 73px;
            width: 96px;
        }
    } 
    
}

.vcard {
    margin-top: 1em;
}

footer {
    height: 60px;
    background-image: url("img/background-footer.png");
    ul {
        display: block;
        text-align: center;
        line-height: 60px;
        li {
            display: inline-block;
            a {
                color: #000;
                text-decoration: none;
                font-size: 0.875em;
                &:hover {
                    color: @navigation-link-hover;
                }
            }
            &.active a {
                text-decoration: underline;
            }
            &:after{
                content :'|';
                
            }
            &:last-child {
                &:after{
                    content : none;
                    
                }
            }
        }

    }
}

.legals {
    display: block;
    width: 866px;
    margin: 1em auto 2em;
    text-align: center;
    color: #C00C15;
    font-family: Arial;
    font-size: 0.625em;
}
